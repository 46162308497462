import { useCallback } from 'react';
import { backendFetch } from '@lib/backendFetch';
import getConfig from 'next/config';
import useParsedUserAgent from 'hooks/useParsedUserAgent';
import { alertModal } from 'vl-common/src/components/Modals';
import Auth from 'aws-amplify';

const { publicRuntimeConfig } = getConfig() || {};

const parseCheckPassed = (check_passed: string | boolean) => {
  if (typeof check_passed === 'string') {
    return check_passed === 'true';
  }
  return check_passed;
};

const browserCheck = async ({
  browser,
  browserVersion,
  os,
  osVersion
}: {
  browser: string;
  browserVersion: string;
  os: string;
  osVersion: string;
}) => {
  const { API_URL } = publicRuntimeConfig;
  const url = new URL(`${API_URL}/browser-check`);
  url.searchParams.append('browser', browser);
  url.searchParams.append('browser_version', browserVersion);
  url.searchParams.append('os', os);
  url.searchParams.append('os_version', osVersion);
  const response = await backendFetch<{ version_check: { check_passed: string; min_browser_version: string } }>(
    String(url),
    {
      method: 'GET',
      unauthenticated: true
    }
  );

  const {
    version_check: { check_passed, min_browser_version: minBrowserVersion }
  } = await response.json();

  return { checkPassed: parseCheckPassed(check_passed), minBrowserVersion };
};

const useBrowserCheckCallback = () => {
  const { browser, browserVersion, os, osVersion } = useParsedUserAgent();

  const browserCheckCallback = useCallback<(toRecord?: boolean) => Promise<void>>(
    async (toRecord = false) => {
      const browserVersionWarningModalShown = localStorage.getItem('browserVersionWarningModalShown');

      if (Auth.authenticatedUser || !browserVersion || !osVersion) return;

      const { checkPassed, minBrowserVersion } = await browserCheck({
        browser,
        browserVersion,
        os,
        osVersion
      });

      if (!toRecord && !checkPassed && !browserVersionWarningModalShown && minBrowserVersion !== '0') {
        alertModal({
          title: 'Browser Version',
          content: `Your current browser version is ${browserVersion}. To get the best experience, we recommend upgrading to version ${minBrowserVersion} or higher.`,
          okText: 'Dismiss',
          onOk: () => {
            localStorage.setItem('browserVersionWarningModalShown', '1');
          }
        });
      }
    },
    [browser, browserVersion, os, osVersion]
  );

  return browserCheckCallback;
};

export default useBrowserCheckCallback;
