import React from 'react';
import Hint from '@components/Login/Hint';
import IdentityProviderButtons from '@components/Login/IdentityProviderButtons';
import LoginTitle from '@components/Login/LoginTitle';
import useRuntimeConfig from '@vl-core/hooks/useConfig';
import { useRouter } from 'next/router';
import { ParsedQuery } from 'query-string';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

type SSOViewProps = {
  returnToLogin?: () => void;
};

interface SSOViewQueryParams extends ParsedQuery {
  optOut: '0' | '1';
}

const SSOView: React.FC<SSOViewProps> = ({ returnToLogin = () => {} }) => {
  const { LUCY_ENV } = useRuntimeConfig();
  const isDev = LUCY_ENV.includes('dev');
  const { t } = useTranslation('common');

  const { pathname, push, query } = useRouter();
  const { optOut } = query as SSOViewQueryParams;

  const setOptOut = (value: boolean) => {
    if (value) query.optOut = '1';
    else delete query.optOut;

    push({ pathname, query });
  };

  if (Number(optOut)) {
    return (
      <>
        <LoginTitle title={['Log', 'in']} emphasis={0} />
        <p>{t('NHSLoginOptedOut')}</p>
        <p>For the safety of your medical data, this is the only way we allow patients to register for the service.</p>
        <p>
          If you would like to talk to a member of our team before registering, please{' '}
          <a href="https://www.hbsuk.co.uk/contact-us" data-test-id="contact-us-link">
            contact us
          </a>
          .
        </p>
        <p>
          <a role="button" tabIndex={0} onClick={() => setOptOut(false)} data-testid="opt-in-link">
            Return to login page
          </a>
        </p>
      </>
    );
  }

  return (
    <div>
      <LoginTitle title={['Log', 'in']} emphasis={0} />

      <p>Access to this service is via NHS login. The link below will direct you to either:</p>

      <ul
        css={css`
          padding-inline-start: 1.5rem;

          li {
            list-style-type: none;
            position: relative;
          }

          li::before {
            content: '•';
            position: absolute;
            left: -1rem;
            font-weight: 600;
            color: var(--productPrimary);
          }
        `}
      >
        <li>Create an NHS login account</li>
        <li>Log in to your existing NHS login account</li>
      </ul>

      <p>We use this method of authentication to keep your medical information secure.</p>

      {/* eslint-disable-next-line test-selectors/button */}
      <IdentityProviderButtons />

      {isDev && (
        <Hint paddingTop>
          <a role="link" onClick={returnToLogin} tabIndex={0} data-testid="use-cognito-login">
            Use Cognito login
          </a>
        </Hint>
      )}

      <Hint paddingTop />
    </div>
  );
};

export default SSOView;
