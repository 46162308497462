import bowser from 'bowser';

const parseVersion = (version: string | null | undefined) => {
  if (!version?.match) return '';
  const [parsedVersion] = version?.match(/([0-9]{0,5}[.])?([0-9]){1,5}/) || ['0.0.0'];
  return parsedVersion;
};

const useParsedUserAgent = () => {
  const parser = bowser.getParser(window.navigator.userAgent);
  const browser = parser.getBrowserName(true);
  const browserVersion = parser.getBrowserVersion();
  const os = parser.getOSName(true);
  const osVersion = parser.getOSVersion();

  return {
    browser,
    browserVersion: parseVersion(browserVersion),
    os,
    osVersion: parseVersion(osVersion)
  };
};

export default useParsedUserAgent;
